// import img1 from "./../../../assets/services/mep/Rectang852.png";
// import img2 from "./../../../assets/services/mep/Rectanglev 852.png";
// import img3 from "./../../../assets/services/mep/Rectangle d852.png";
// import img4 from "./../../../assets/Rectangle--852.png";

// import imgDetails1 from "./../../../assets/services/engineering/engineeringDetails/Mask3.png";
// import imgDetails2 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 125.png";
// import imgDetails3 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 126.png";
// import imgDetails4 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 123.png";
// import imgDetails5 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle c125.png";
// import imgDetails6 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle d126.png";
// import imgDetails7 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle f123.png";

// import imgDetails8 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangled 125.png";
// import imgDetails9 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectanglيe 126.png";
// import imgDetails10 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangl123.png";
// import imgDetails11 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangl1e 125.png";
// import imgDetails12 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1226.png";
// import imgDetails13 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle2 123.png";

// import imgDetails14 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangele 125.png";
// import imgDetails15 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangl5e 126.png";
// import imgDetails16 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1ث23.png";
// import imgDetails17 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1425.png";
// import imgDetails18 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectanglي126.png";

// import img5 from "./../../../assets/Artboard 6 1.png";

// import backgroundImage1 from "./../../../../assets/services/HERO-SECTION-WEB/Rectangle5446.jpg";
// import backgroundImage2 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189.jpg";
// import backgroundImage3 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-1.jpg";

// import imgMobile1 from "./../../../../assets/services/Mechanical Engineering/DSC_0218 copy.jpg";
// import imgMobile2 from "./../../../../assets/services/Mechanical Engineering/IMG-20230501-WA0004 copy.jpg";
// import imgMobile3 from "./../../../../assets/services/Mechanical Engineering/DSC_0194 copy.jpg";
// import imgMobile4 from "./../../../../assets/services/Mechanical Engineering/DSC_0193 copy.jpg";
// import imgMobile5 from "./../../../../assets/services/Mechanical Engineering/DSC_0180 copy.jpg";

// import backgroundImage1 from "./../../../../assets/home/error.svg";

const Data = [
  {
    id: 1,
    img: "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/services/mep/Rectang852.png",
    imgDesktop:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesMechanicalEngineering/MechanicalEngineering.jpg",

    imgMobile:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/MechanicalEngineering/Artboard1_2.png",
    // imgDetails1: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/services/engineering/engineeringDetails/Mask.png',

    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 125.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 126.png",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 123.png",

    imgDetails1:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/MechanicalEngineering/DSC_0218 copy.jpg",
    imgDetails2:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/MechanicalEngineering/IMG-20230501-WA0004 copy.jpg",
    imgDetails3:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/MechanicalEngineering/DSC_0194 copy.jpg",
    imgDetails4:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/MechanicalEngineering/DSC_0193 copy.jpg",
    imgDetails5:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/MechanicalEngineering/DSC_0180 copy.jpg",
    // imgDetails6: imgDetails6,
    // imgDetails7: imgDetails7,

    // imgDetails8: imgDetails7,
    // imgDetails9: imgDetails7,
    // imgDetails10: imgDetails7,
    // imgDetails11: imgDetails7,
    // imgDetails12: imgDetails7,
    // imgDetails13: imgDetails7,
    // imgDetails14: imgDetails7,
    // imgDetails15: imgDetails7,

    title1: "Mep services",
    title: "Mechanical Engineering",
    description:
      "Our HVAC Engineering service provides mechanical designs that include Heating, Ventilation, and Air Conditioning (HVAC) systems, as well as Central Plant Design and Exhaust Systems.",
    description2:
      " Additionally, we offer Direct  Digital Control (DDC) Systems, Chilled Water Systems, and Heating Water Systems.",
    // description3:'22222222'
  },
  {
    id: 2,
    img: "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/services/mep/Rectanglev 852.png",
    imgDesktop:
      // "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Group19189.jpg",
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesElectricEngineering/ Electrical-Engineering.jpg",
    imgMobile:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesElectricEngineering/Artboard11.png",

    imgDetails4:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ElectricEngineering/male-electrician-works-switchboard-with-electrical-connecting-cable copy.jpg",

    imgDetails2:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ElectricEngineering/man-electrical-technician-working-switchboard-with-fuses-installation-connection-electrical-equipment-close-up copy.jpg",

    imgDetails5:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ElectricEngineering/IMG-20230501-WA0009 copy.jpg",

    imgDetails6:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ElectricEngineering/DSC_0176 copy.jpg",

    imgDetails1:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ElectricEngineering/man-electrical-technician-working-switchboard-with-fuses-uses-tablet copy.jpg",

    imgDetails3:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ElectricEngineering/man-electrical-technician-working-switchboard-with-fuses copy.jpg",

    // imgDetails7: '',

    title1: "Mep services",
    title: "Electrical Engineering",
    description:
      "In addition to providing lightning protection devices and fire alarm systems, Laurent Electrical Engineering also offers Integrated electrical system design, critical power system device coordination, and Arc Fault Services.",
    description2:
      "Our Electrical Engineering services include low-current electrical technologies such as security and Alarm system synchronization.  ",
    description3:
      "Communication Technologies, Audio/Visual System Design, Voice, Data, and Video integration are among our electrical engineering services. ",
    description4:
      "Our ultimate goal for all projects is to create safe, efficient, and pleasant systems with high-quality devices.",
    // description3:'',

    // link:'https://www.youtube.com/'
  },
  {
    id: 3,
    img: "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/services/mep/Rectangle d852.png",
    imgDesktop:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesPlumbing/PlumbingEngineering.jpg",
    imgMobile:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesPlumbing/Artboard1.png",
    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle f123.png",

    imgDetails2:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/steel-pipelines-cables-factory-interior-as-industrial-zone-background-concept copy.jpg",
    imgDetails4:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/Rectangle 126 copy.jpg",
    imgDetails5:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/Rectangle 123 copy.jpg",

    imgDetails3:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/Rectangle 127 copy.jpg",
    imgDetails1:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/steel-pipelines-cables-plant copy.jpg",

    imgDetails6:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/AdobeStock_238319918 copy.jpg",
    imgDetails7:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Plumbing/AdobeStock_191296720 copy.jpg",
    // imgDetails2: imgDetails15,
    // imgDetails3: imgDetails16,
    // imgDetails4: imgDetails17,
    // imgDetails5: imgDetails18,
    // imgDetails6: imgDetails14,
    title1: "Mep services",
    title: "Plumbing Engineering",
    description:
      "Laurent provides Sustainable Plumbing solutions. Our plumbing experts design reliable systems at any scale. ",
    description2:
      "Plumbing engineering services include:  Water Conservation Systems  filtration systems  Sanitary and Storm Systems  Firefighting systems",
    // description3:'',
    // link:''
  },
  // {
  //   id: 4,
  //   img: img4,
  //   imgDetails1: imgDetails1,
  //   imgDetails2: img2,
  //   imgDetails3: img2,
  //   imgDetails4: img2,
  //   imgDetails5: img2,
  //   title: "Residential construction",
  //   // link:''
  // },

  // {
  //   // id: 5,
  //   // img: img5,
  //   // title: "HUMILITY",
  //   // link:''
  // },
  // {
  //   // id: 6,
  //   // img: img6,
  //   // title: "HUMILITY",
  //   // link:''
  // },
];
export default Data;
